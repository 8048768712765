.pb-app-body {
  a.vstart-header-logo {
    text-decoration: none;
    
    img {
      width: 200px;
    }
  }

  .info-banner {
    background-color: var(--vstart-color-base-lighter);

    p a {
      font-weight: 700;
      color: #152369;
    }
  }
}
