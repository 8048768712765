.view-complaint-wrapper {
  h4 {
    margin-top: 1rem;
  }

  div.inline-section {
    display: flex;
    flex-direction: row;
  }

  section {
    padding: 0.5rem 2rem 1rem 0;

    span {
      display: block;

      &:first-child {
        font-weight: bold;
      }
    }
  }
}
