.interference-details-wrapper {
  #locations-data-wrapper {
    position: relative;
    z-index: 2;

    #locations-data {
      position: absolute;
      background: #fff;
      width: 100%;
      max-height: 250px;
      overflow: scroll;
      border: solid 1px var(--vstart-color-base-light);

      > div {
        padding: 0.25rem 0.7rem;

        &:hover {
          background-color: var(--vstart-color-base-light);
        }
      }
    }
  }
}

.lat-lon-wrapper {
  > div {
    > label {
      display: block;
    }

    .lat-lon-dddmmss > div {
      display: inline-block;
      margin-right: 1rem;

      .invalid-feedback {
        position: absolute;
      }
    }
  }

  .lat-lon {
    width: 80px !important;
  }

  .lat-lon-decimal {
    width: 125px !important;
  }
}
