:root {
  --vstart-link-color: #23b1b8;
  --vstart-color-white: #fff; //#{$theme-white};

  --vstart-color-base-lightest: #f5f5f6; // #{$theme-base-lightest};
  --vstart-color-base-lighter: #eaeced; // #{$theme-base-lighter};
  --vstart-color-base-light: #e0e2e3; // #{$theme-base-light};
  --vstart-color-base: #c1c5c8; // #{$theme-base};
  --vstart-color-base-dark: #9aa0a5; // #{$theme-base-dark};
  --vstart-color-base-darker: #6f787f; // #{$theme-base-darker};
  --vstart-color-base-darkest: #202737; // #{$theme-base-darkest};
  --vstart-color-ink: #212121; // #{$theme-base-ink};

  --vstart-color-primary-lighter: #e0e2e3; // #{$theme-primary-lighter};
  --vstart-color-primary-light: #323e49; // #{$theme-primary-light};
  --vstart-color-primary-vivid: #4eb6bc; // #{$theme-primary-vivid};
  --vstart-color-primary: #282828; // #{$theme-primary-base};
  --vstart-color-primary-dark: #333; // #{$theme-primary-dark};
  --vstart-color-primary-darker: #000; // #{$theme-primary-darker};

  --vstart-color-secondary-lightest: #f5f5f6; // #{$theme-base-lightest};
  --vstart-color-secondary-lighter: #eaeced; // #{$theme-base-lighter};
  --vstart-color-secondary-light: #e0e2e3; // #{$theme-base-light};
  --vstart-color-secondary: #c1c5c8; // #{$theme-base};
  --vstart-color-secondary-dark: #9aa0a5; // #{$theme-base-dark};
  --vstart-color-secondary-darker: #6f787f; // #{$theme-base-darkest};
  --vstart-color-secondary-darkest: #212121; // #{$theme-base-ink};

  --vstart-collapsible-section-border-left: #323e49;
  --vstart-collapsible-section-border-left-hover: #4eb6bc;
  --vstart-collapsible-section-background-hover: #e0e2e3;
}
