@import '~@ventera-corporation/vstart-theme-ventera/default';
@import '../assets/styles/colors.scss';
@import '../assets/styles/variables.scss';

header {
  position: relative;
  z-index: 2;
  padding: 1rem 4rem 0 4rem;

  // overrides
  .vstart-header {
    border-bottom: solid 1px var(--vstart-color-secondary-darker);

    .vstart-nav {
      #Home {
        display: block;
      }

      .vstart-nav-list {
        padding: 1rem;
        margin-bottom: -0.4rem;
      }

      .nav-link.active {
        color: var(--vstart-color-primary);

        &:after {
          background-color: var(--vstart-color-primary-vivid);
        }
      }
    }
  }
}

// need to override theme for some things
.btn-primary {
  border-radius: 2rem;
  background-color: var(--vstart-color-primary);
  border-color: var(--vstart-color-primary);

  &:hover, &:active {
    background-color: #59BEC8 !important;
  }

  &:focus {
    color: var(--vstart-color-white);
    background-color: #3b9297 !important;
    border-color: #3b9297 !important;
    box-shadow: 0 0 0 0.25rem rgba(38, 108, 148, 0.5) !important;
  }

  &:disabled {
    background-color: var(--vstart-color-base-dark);
    border-color: var(--vstart-color-base-dark);
  }
}

.btn-outline-primary {
  border-radius: 2rem;
  color: var(--vstart-color-primary);
  border-color: var(--vstart-color-primary);

  &:hover, &:active {
    border-color: var(--vstart-color-primary-lighter) !important; 
    background-color: transparent !important;
    color: var(--vstart-color-primary) !important;
  }

  &:focus {
    color: var(--vstart-color-secondary-darkest);
    background-color: var(--vstart-color-primary-lighter) !important;
    border-color: var(--vstart-color-primary-lighter) !important;
    box-shadow: 0 0 0 0.25rem rgba(38, 108, 148, 0.5) !important;
  }
}

.btn-link {
  color: var(--vstart-link-color);
  font-weight: 500;
  padding: 0;
}

.btn-secondary {
  border-radius: 2rem;
  background-color: var(--vstart-color-primary-vivid);
  border-color: var(--vstart-color-primary-vivid);
  color: var(--vstart-color-secondary-darkest);

  &:hover {
    color: var(--vstart-color-white);
  }
}

// site-specific
.ctia-content-wrapper {
  select {
    appearance: none;
  }
  .select-field{
    position: relative;

    &:before {
      position: absolute;
      top: 3rem;
      right: 0.5rem;
      color: #768093;
      font-size: 20px;
      content: "\2304";
    }
  }

  .ctia-content-header {
    padding: 2rem 0;
  }

  .ctia-content-title {
    > div.container {
      align-items: center;

      h1 {
        flex-grow: 1;
      }

      h2 {
        font-size: 1.25rem;
        display: flex;
        align-items: center;

        span {
          font-size: 2rem;
          padding-left: 1rem;
        }
      }
    }
  }

  .ctia-content {
    padding: 2rem 0;
    background-color: var(--vstart-color-secondary-lightest);

    small.form-text {
      color: var(--vstart-color-secondary-darker);
    }    
  }
}
