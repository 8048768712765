.run-reports-wrapper {
  .content-wrapper {
    position: relative;
    flex: 75% 0 0;

    .chart-title {
      font-size: 1.25rem;
    }

    .chart-message {
      position: absolute;
      width: 100%;
      top: 50%;
      height: 350px;
      display: flex;
      align-items: center;
      font-weight: 700;
      
      span {
        width: 100%;
        text-align: center;
      }
    }
  }

  .form-date-wrapper {
    display: flex;

    > div {
      width: 45%;

      label {
        color: var(--vstart-color-secondary-darkest);
      }
    }
  }

  // vstart form overrides
  .run-reports-actions-wrapper {
    padding: 2rem;
  
    .vstart-form {
      padding: 0;
      margin: 0;
    }

    fieldset label, h3 {
      font-weight: 500;
      font-size: 1.25rem;
      margin: 0;
      padding: 0;
    }

    p small, p {
      color: var(--vstart-color-secondary-darkest);
      margin: 0;
      padding: .5rem 0;
    }

    .form-check {
      display: inline-block;
      padding-right: 1rem;

      label {
        font-size: 1rem;
      }
    }

    ul {
      margin: 0.5rem 0;
      padding: 0;

      li {
        list-style: none;
      }
    }

    button {
      width: auto;
    }
  }

  .chart-hover {
    fill: #52c8ce;
  }
}
