#sign-in-wrapper {
  position: relative;
  z-index: 1;

  #top-content-wrapper {
    text-align: center;
    background: top left no-repeat url('../../../../assets/images/bg_left.svg');
    background-size: contain;
    min-height: 350px;
    position: relative;
  }
  #splash {
    display: flex;
    padding: 2rem;
    text-align: center;
    background: top right no-repeat url('../../../../assets/images/bg_right.svg');
    background-size: contain;
    min-height: 350px;
    position: relative;
    z-index: 2;

    #welcome {
      width: 70%;
      margin: auto;
      background-color: var(--vstart-color-white);
      padding: 1rem;

      @media (max-width: 576px) {
        width: 90%;
      }

      .buttons {
        button {
          margin: 0.5rem;
          white-space: wrap;
        }
      }

      a.btn-link {
        font-weight: 700;
        color: #333;
      }
    }
  }

  #content-wrapper {
    display: flex;
    align-items: center;
    padding: 4rem 0rem;
    min-height: 300px;
    background-color: var(--vstart-color-secondary-lightest);

    .content {
      width: 70%;
      margin: auto;
      padding: 1rem;
    }
  }
}

@media screen and (min-width: 576px) {
  #sign-in-wrapper {
    #splash {
      #welcome {
        .buttons {
          button {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
