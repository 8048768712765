footer {
  background-color: var(--vstart-footer);
  padding: 32px 0 16px 0;

  .container {
    display: flex;
    flex-direction: column;

    > div {
      padding: 16px 0;
    }
  
    .firstRow {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div:first-child {
        margin-right: 32px;
      }

      .footerNav {
        display: flex;
        flex-direction: row;
        
        > div {
          padding: 0 16px;
        }
      }
    }
  }

  h3 {
    font-size: 1.2em;
    font-weight: 700;
    color: #fff;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 0.5rem;
    }
  }

  span, a {
    color: #fff;
    font-size: 0.9em;
  }
}

// these widths are arbitrary - we should use proper widths
@media screen and (max-width: 500px) {
  footer {
    .container {
      .firstRow {
        flex-direction: column;

        > div:first-child {
          margin-right: 0;
          margin-bottom: 32px;
        }
      }
    }
  }
}
