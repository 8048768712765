.date-time-wrapper {
  > div {
    > label:first-child {
      display: none;
    }

    > div label {
      display: block;
    }
  }

  .hours-minutes {
    width: 125px !important;
  }

  .time-wrapper {
    > div {
      display: inline-block;

      &:first-child {
        label {
          display: block;
        }
      }
    }
    .ampm-wrapper fieldset .form-check {
      display: inline-block;
      margin-left: 1rem;
    }

    .invalid-feedback {
      position: absolute;
    }
  }
}
